import $ from "jquery";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
var current_width = $(window).width(),
    zoom = 4,
    iniCenter = [3.551, -2.6],
    className = "",
    map_container = "resourcesMap",
    clickedState = null,
    hoverColor = "",
    hoveredState = false,
    isMobile = false;

window.initMap = function initMap(data) {
    hoverColor = data["hoverColor"];
    if (current_width >= 1200) {
        $(".map-wrapper").css("height", "700px");
    } else if (current_width >= 1024 && current_width < 1200) {
        zoom = 3.7; //set map zoom level for desktop size
        iniCenter = [0.551, -4.6];
        $(".map-wrapper").css("height", "700px");
    } else if (current_width >= 768 && current_width < 1024) {
        zoom = 3.2; //set map zoom level for mobile size
        iniCenter = [0.551, -4.6];
        $(".map-wrapper").css("height", "500px");
    } else {
        isMobile = true;
        zoom = 2.2; //set map zoom level for mobile size
        iniCenter = [3.551, -2.6];
        className = "mobilePopUp";
        $(".map-wrapper").css("height", "250px");
        let mobileStatesList = "";
        let index = 0;
        for (const key in data["features"]) {
            if (Object.hasOwnProperty.call(data["features"], key)) {
                let badge = `
                    <div class="col-12 px-0">
                        <div class="legend-circle" data-state="${key}">${key}</div>
                    </div>
                `;

                if (index == 0) {
                    badge = `<div class="col-4 px-1"><div class="row">` + badge;
                } else if (index == 6) {
                    badge = badge + "</div></div>";
                }

                mobileStatesList = mobileStatesList + badge;
                index = index == 6 ? 0 : index + 1;
            }
        }
        $("#states-circle").html(`
            <div class="container ">
                <div class="row justify-content-center ps-3 mobileStatesList">${mobileStatesList}</div>
            </div>
        `);
    }

    mapboxgl.accessToken =
        "pk.eyJ1IjoidG9iYWNjb2ZyZWVraWRzIiwiYSI6IlQ3bEdmNW8ifQ.aFJ5TdySQqX7x4ecxAp3Ug";

    const map = new mapboxgl.Map({
        container: map_container,
        style: "mapbox://styles/tobaccofreekids/cl09wli9v001x14lc7370njdl",
        center: iniCenter,
        minZoom: zoom,
        scrollZoom: false,
        boxZoom: false,
        doubleClickZoom: false,
    });

    if (!isMobile) {
        $("#states-circle .legend-circle").each(function (index, element) {
            let state = $(element).data("state");
            if (data["activeStates"].includes(state) ) {
                $(element).css("background", hoverColor);
                $(element).addClass("hasEntry");
            }
        });
    }
    // map.removeControl(map.getControl('navigation'));
    map._controls.forEach((control) => map.removeControl(control))
    map.on("load", () => {
        map.resize();
        map.addSource("states", {
            type: "vector",
            url: "mapbox://mbxsolutions.albersusa",
        });

        map.setPaintProperty("counties", "fill-color", [
            "match",
            ["get", "state_name"],
            data["activeStates"],
            hoverColor,
            "#f0f0f0",
        ]);

        map.on("mousemove", "counties", (e) => {
            map.getCanvas().style.cursor = "pointer";
        });
        map.on("click", "counties", (e) => {
            if (clickedState) {
                $(".mapboxgl-popup-close-button").click();
                clickedState = null;
            }
            if (e.features.length > 0) {
                clickedState = true;
               
                showCountryClusterInfo(
                    map,
                    data,
                    e.features[0]["properties"]["state_name"],
                    [e.lngLat.lng, e.lngLat.lat]
                );
            }
        });

        $(".legend-circle.hasEntry").click(function (e) {
            if (clickedState) {
                $(".mapboxgl-popup-close-button").click();
                clickedState = null;
            }
            clickedState = true;
            showCountryClusterInfo(map, data, $(this).data("state"), [25, 0]);
        });
    });
};

function showCountryClusterInfo(map, jdata, stateName, lngLat) {
    if (stateName in jdata["features"]) {
        let feature = jdata["features"][stateName];

        var stateDescription = feature.properties.comments,
            popup = new mapboxgl.Popup({ className: className })
                .setLngLat(lngLat)
                .setHTML(
                    "<div class='stateDetails'><h3>" +
                        feature.properties.stateName +
                        "</h3><div class='stateDescription'>" +
                        stateDescription +
                        "</div></div>"
                )
                .addTo(map);

        $(".mapboxgl-popup-close-button").click(function (e) {
            clickedState = null;
        });
        if (className) {
            let popUpCoo = $(".mobilePopUp").offset().top;
            $(window).scrollTop(popUpCoo);
        }
    } else {
        $(".mapboxgl-popup-close-button").click();
        clickedState = null;
    }
}
